import Vue from 'vue'
import Router from 'vue-router'
import TopPage from './components/TopPage.vue'
import SearchPage from './components/SearchPage.vue'
import ResultPage from './components/ResultPage.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASEURL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: TopPage,
    },
    {
      path: '/search_form',
      name: 'search',
      component: SearchPage,
    },
    {
      path: '/search_result',
      name: 'result',
      component: ResultPage,
      props: true,
    },
  ]
})