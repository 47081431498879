// <!DOCTYPE>, <html>, <head> タグの編集を mixin するコンポーネント

export default {
    mounted: function () {
        // <!DOCTYPE> の設定
        document.doctype.replaceWith(
            document.implementation.createDocumentType(
                "html",
                "-//W3C//DTD XHTML 1.0 Transitional//EN",
                "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"));

        // <html> の設定
        document.documentElement.setAttribute('xmlns', "http://www.w3.org/1999/xhtml");
        document.documentElement.setAttribute('xml:lang', 'ja');
        document.documentElement.setAttribute('lang', 'ja');

        // <meta> の設定
        var metaDisc = document.head.children;
        var metalen = metaDisc.length;
        for (var i = 0; i < metalen; i++) {
            var proper = metaDisc[i].getAttribute('http-equiv');
            if (proper) {
                metaDisc[i].setAttribute('http-equiv', 'Content-Type');
                metaDisc[i].setAttribute('content', 'text/html; charset=UTF-8');
            }
        }

        // <title> の設定を option から読み取る
        let { title } = this.$options;
        if (title) {
            title = typeof title === 'function' ? title.call(this) : title;
            document.title = title;
        }
    }
}