<!--
Dialog.vue: 利用条件確認ダイアログを表示する vue コンポーネント

open_dialog() で表示、close_dialog() メソッドで非表示にする。
OK ボタンがクリックされた場合には検索ページへと遷移する。
-->

<template src="@/assets/html/dialog_terms_of_use.html"></template>

<script>
export default {
  data() {
    return {
      isCloseButtonHover: false, // 右上の x ボタンがマウスオーバー中かどうか表すフラグ
      isOkButtonHover: false, // OK ボタンがマウスオーバー中かどうか表すフラグ
      isCancelButtonHover: false, // Cancel ボタンがマウスオーバー中かどうか表すフラグ
    };
  },
  beforeCreate: function () {
    document.body.className = "body-toppage";
  },
  methods: {
    open_dialog() {
      this.$modal.show("dialog-terms-of-use");
    },
    close_dialog() {
      this.$modal.hide("dialog-terms-of-use");
    },
    /**
     * ダイアログで OK ボタンを押下した際に実行する関数
     */
    accept() {
      // API にアクセスし、Cookie に検索用の乱数を追加する
      this.$api.get("/session", { withCredentials: true });
      this.$router.push({
        name: "search",
        params: {},
      });
    },
  },
};
</script>

<style src="../../public/css/dialog.css"></style>
<style scoped src="../../public/css/base.css"></style>
<style scoped src="../../public/css/retro/jquery-ui-1.9.0.custom.css"></style>
