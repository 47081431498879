<!--
SearchTemplate.vue: 検索ページ共通のヘッダー画像を表示するためのテンプレート

<template v-slot:search-body> にヘッダー画像以下の表示コンテンツを挿入する。
-->

<template>
  <div id="wrapper">
    <div id="header">
      <table border="0" cellspacing="0">
        <tr>
          <td><img src="/img/head01.gif" width="300" height="50" /></td>
          <td class="headR"></td>
        </tr>
        <tr>
          <td colspan="2">
            <img
              src="/img/search_demo2.gif"
              alt="KOTONOHA「現代書き言葉均衡コーパス」&emsp;検索デモンストレーション"
              width="700"
              height="148"
            />
          </td>
        </tr>
      </table>
    </div>
    <slot name="search-body"></slot>
  </div>
</template>

<script>
export default {
  name: "SearchTemplate",
  beforeCreate: function () {
    document.body.className = "body-search";
  },
};
</script>

<style scoped src="../../public/css/search.css"></style>