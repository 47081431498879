<!--
SearchSubSubCategory.vue: 検索サブサブカテゴリ (サブジャンル、年) を表示するコンポーネント

自身のチェックボックスがチェックされた際に上位カテゴリの checked イベントを発火する。
上位カテゴリのチェックが入ったことを props の情報で確認した場合には自身のチェックを外す。
-->

<template src="@/assets/html/search_form_subsubcategory.html"></template>

<script>
import SearchCategoryTemplate from "../components/SearchCategoryTemplate.vue";

export default {
  extends: SearchCategoryTemplate,
  name: "SearchSubSubCategory",
};
</script>

<style scoped src="../../public/css/search.css"></style>
