<!--
SearchCategory.vue: 検索カテゴリ (メディア、全期間) を表示するコンポーネント

自身のチェックボックスがチェックされた際に下位カテゴリにチェック情報を渡す。
また、下位カテゴリから checked イベントが発火された場合には自身のチェックを外す。
トグルスイッチによる下位カテゴリの表示/非表示フラグを持ち、下位カテゴリのコンポーネントにフラグの情報を渡す。
-->

<template src="@/assets/html/search_form_category.html"></template>

<script>
import SearchSubCategory from "./SearchSubCategory.vue";
import SearchCategoryTemplate from "../components/SearchCategoryTemplate.vue";

export default {
  name: "SearchCategory",
  extends: SearchCategoryTemplate,
  components: { SearchSubCategory },
  data() {
    return {
      default_check: true, // チェックの初期状態を表すフラグ
      toggle_open: false, // 下位カテゴリを表示するかどうかを表すフラグ
    };
  },
  mounted() {
    this.open_checked_toggle();
  },
  methods: {
    /**
     * session storage を参照し、下位カテゴリにチェックがついている場合には
     * toggle_open を true にして下位カテゴリを表示する
     */
    open_checked_toggle() {
      // 自身の下位カテゴリの情報を全て取得する
      var subcats = this.subcat_info;
      if (subcats) {
        // サブカテゴリでループ
        for (const subcat of subcats) {
          var s_name = subcat.name;
          var s_value = subcat.value;
          // session storage に指定したサブカテゴリが含まれていればフラグを true にする
          if (
            sessionStorage["checked_" + s_name] &&
            sessionStorage["checked_" + s_name].includes(s_value)
          ) {
            this.toggle_open = true;
            // true になったらループから抜ける
            break;
          }
          if (!subcat.subcategories) {
            continue;
          }
          // サブサブカテゴリでループ
          for (const subsubcat of subcat.subcategories) {
            var ss_name = subsubcat.name;
            var ss_value = subsubcat.value;
            // session storage に指定したサブサブカテゴリが含まれていればフラグを true にする
            if (
              sessionStorage["checked_" + ss_name] &&
              sessionStorage["checked_" + ss_name].includes(ss_value)
            ) {
              this.toggle_open = true;
              break;
            }
          }
          // true になったらループから抜ける
          if (this.toggle_open) {
            break;
          }
        }
      }
    },
  },
};
</script>

<style scoped src="../../public/css/search.css"></style>
