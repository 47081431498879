<!--
SearchSubCategory.vue: 検索サブカテゴリ (ジャンル、年代) を表示するコンポーネント

自身のチェックボックスがチェックされた際に下位カテゴリにチェック情報を渡し、上位カテゴリの checked イベントを発火する。
上位カテゴリのチェックが入ったことを props の情報で確認した場合には自身のチェックを外す。
下位カテゴリから checked イベントが発火された場合には自身のチェックを外す。
上位カテゴリのトグルスイッチのフラグが変更された場合には、自身を非表示にする。
-->

<template src="@/assets/html/search_form_subcategory.html"></template>

<script>
import SearchSubSubCategory from "./SearchSubSubCategory.vue";
import SearchCategoryTemplate from "../components/SearchCategoryTemplate.vue";

export default {
  extends: SearchCategoryTemplate,
  components: { SearchSubSubCategory },
  name: "SearchSubCategory",
  props: [
    "toggle_open", // 自身を表示するかどうかのフラグ
  ],
};
</script>

<style scoped src="../../public/css/search.css"></style>
