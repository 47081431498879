import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VModal from 'vue-js-modal'
import router from './router'
import HeadConfig from './head_config'
import 'babel-polyfill'
import VueGtag from "vue-gtag"

if (process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    Vue.use(VueGtag, {
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID }
    }, router);
}
Vue.use({
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL,
            timeout: process.env.VUE_APP_API_TIMEOUT_MS,
        });
        Vue.prototype.$results = { "Results": [], "TotalCount": 0 };
    }
});
Vue.use(VModal);
Vue.mixin(HeadConfig);

const vue = new Vue({
    router,
    render: h => h(App),
});
vue.$mount('#app');
