<!--
TopPage.vue: トップページを表示する vue コンポーネント
-->

<template src="@/assets/html/index.html"></template>

<script>
import Dialog from "./Dialog.vue";

export default {
  title: "KOTONOHA「現代日本語書き言葉均衡コーパス」　少納言",
  name: "TopPage",
  components: {
    Dialog,
  },
  data() {
    return {
      query_string_max_length: 10, // 検索文字列の最大長
      img_demoban: require("../../public/img/demoban_off.gif"), // "利用条件に同意して少納言を使う" ボタンの画像
    };
  },
  beforeCreate: function () {
    document.body.className = "body-toppage";
  },
  methods: {
    /**
     * "利用条件に同意して少納言を使う" ボタンにマウスオーバーした際の処理
     */
    mouseover_demoban() {
      this.img_demoban = require("../../public/img/demoban_on.gif");
    },
    /**
     * "利用条件に同意して少納言を使う" ボタンからマウスカーソルを離した際の処理
     */
    mouseleave_demoban() {
      this.img_demoban = require("../../public/img/demoban_off.gif");
    },
    /**
     * "利用条件に同意して少納言を使う" ボタンをクリックした際の処理
     */
    open_dialog() {
      this.$refs.dialog.open_dialog();
    },
  },
};
</script>

<style scoped src="../../public/css/base.css"></style>
<style scoped src="../../public/css/retro/jquery-ui-1.9.0.custom.css"></style>
