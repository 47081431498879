<!--
SearchCategoryTemplate.vue: 検索画面内のカテゴリ表示をするためのテンプレート
上位カテゴリからチェックの変更を受け取るための prop と、
下位カテゴリからの checked イベント発火時の callback 関数を設定する。
-->

<script>
export default {
  name: "SearchCategoryTemplate",
  components: {},
  props: [
    "cat", // 自身のカテゴリ情報と下位カテゴリ情報を持つオブジェクト
    "checked", // 上位カテゴリのチェック状態の情報
    "check_all", // "全てにチェックを入れる" ボタンクリック時にフラグが切り替わる
    "uncheck_all", // "全てのチェックを外す" ボタンクリック時にフラグが切り替わる
  ],
  data() {
    return {
      cat_cb_checked: false, // 自身のチェック状態を表すフラグ
      default_check: false, // チェックの初期状態を表すフラグ
    };
  },
  beforeCreate: function () {
    document.body.className = "body-search";
  },
  mounted: function () {
    // sessionStorage から前の検索条件を読み出す。sessionStorage に存在しない場合は default_check を利用する。
    if (sessionStorage["checked_" + this.cat_name]) {
      this.cat_cb_checked = sessionStorage["checked_" + this.cat_name]
        .split(",")
        .includes(String(this.cat_value));
    } else if (sessionStorage["checked_" + this.cat_name] == "") {
      this.cat_cb_checked = false;
    } else {
      this.cat_cb_checked = this.default_check;
    }
  },
  watch: {
    /**
     * 上位カテゴリのチェック状態が変更された際の処理
     */
    checked(value) {
      if (value) {
        this.cat_cb_checked = false;
      }
    },
    /**
     * 自身のチェック状態が変更された場合の処理
     * @param {boolean} value 変更後のチェック状態
     */
    cat_cb_checked(value) {
      if (value) {
        // true になった場合は上位カテゴリのイベントを発火する
        this.$emit("checked");
        this.check_self();
      } else {
        this.uncheck_self();
      }
    },
    /**
     * "全てにチェックを入れる" ボタンが押されフラグが切り替わった場合に実行される関数
     */
    check_all(new_value, old_value) {
      if (new_value != old_value) {
        this.cat_cb_checked = true;
      }
    },
    /**
     * "全てのチェックを外す" ボタンが押されフラグが切り替わった場合に実行される関数
     */
    uncheck_all(new_value, old_value) {
      if (new_value != old_value) {
        this.cat_cb_checked = false;
      }
    },
  },
  computed: {
    /**
     * 自身または上位カテゴリがチェックされているかどうかのフラグ
     */
    upper_layer_checked: function () {
      return this.cat_cb_checked | this.checked;
    },
    /**
     * 自身のカテゴリ分類名 (メディア・ジャンル・サブジャンルなど)
     */
    cat_name: function () {
      return this.cat.name;
    },
    /**
     * 自身のカテゴリ名
     */
    cat_value: function () {
      return this.cat.value;
    },
    /**
     * サブカテゴリ情報
     */
    subcat_info: function () {
      return this.cat.subcategories;
    },
  },
  methods: {
    /**
     * 下位カテゴリから "checked" イベントが発火された際に実行されるコールバック関数
     * 自身のチェックを外し、さらに上位カテゴリのチェックも外すために "checked" イベントを発火する
     */
    checked_callback() {
      this.cat_cb_checked = false;
      this.$emit("checked");
    },
    /**
     * 自身がチェックされたときに実行する関数
     * チェックされたカテゴリ名を引数に上位のイベントを発火し、入力フォームに登録する
     */
    check_self() {
      this.$emit("check", this.cat_name, this.cat_value);
    },
    /**
     * 自身のチェックが外されたときに実行する関数
     * チェックされたカテゴリ名を引数に上位のイベントを発火し、入力フォームから除外する
     */
    uncheck_self() {
      this.$emit("uncheck", this.cat_name, this.cat_value);
    },
    /**
     * 下位カテゴリがチェックされた際に実行する関数
     * チェックされたカテゴリ名を引数に上位のイベントを発火し、入力フォームに登録する
     * @param {string} name 下位カテゴリの分類名
     * @param {string} value 下位カテゴリ名
     */
    pass_check(name, value) {
      this.$emit("check", name, value);
    },
    /**
     * 下位カテゴリのチェックが外されたときに実行する関数
     * チェックされたカテゴリ名を引数に上位のイベントを発火し、入力フォームから除外する
     * @param {string} name 下位カテゴリの分類名
     * @param {string} value 下位カテゴリ名
     */
    pass_uncheck(name, value) {
      this.$emit("uncheck", name, value);
    },
  },
};
</script>

<style scoped src="../../public/css/search.css"></style>
