<!--
ResultPage.vue: 検索結果ページを表示する vue コンポーネント
-->

<template src="@/assets/html/result_page.html"></template>

<script>
import SearchTemplate from "./SearchTemplate.vue";
import SearchResult from "./SearchResult.vue";
import SearchError from "./SearchError.vue";

export default {
  name: "ResultPage",
  components: {
    SearchTemplate,
    SearchResult,
    SearchError,
  },
  props: {
    success: { type: Boolean, default: false }, // 検索成功したかどうかのフラグ
    message: {
      // 検索失敗の場合のエラーメッセージ
      type: String,
      default:
        "システム内部でエラーが発生しました。ご迷惑をおかけして申し訳ありません。",
    },
  },
};
</script>

<style scoped src="../../public/css/search.css"></style>
