import { render, staticRenderFns } from "@/assets/html/search_form.html?vue&type=template&id=d2c0127c&scoped=true&"
import script from "./SearchPage.vue?vue&type=script&lang=js&"
export * from "./SearchPage.vue?vue&type=script&lang=js&"
import style0 from "../../public/css/search.css?vue&type=style&index=0&id=d2c0127c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2c0127c",
  null
  
)

export default component.exports