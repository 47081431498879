<!--
SearchPage.vue: 検索ページを表示する vue コンポーネント
-->

<template src="@/assets/html/search_form.html"></template>

<script>
import SearchTemplate from "./SearchTemplate.vue";
import SearchCategory from "./SearchCategory.vue";
import Vue from "vue";

export default {
  title: "KOTONOHA「現代日本語書き言葉均衡コーパス」　少納言 - 検索画面",
  name: "SearchPage",
  components: {
    SearchTemplate,
    SearchCategory,
  },
  data() {
    return {
      counter: 0, // 検索者数
      query_string_max_length: 10, // 最大検索文字サイズ
      query_string: "", // 検索文字列
      lcontext_regex: "", // 前文脈の正規表現
      rcontext_regex: "", // 後文脈の正規表現
      show_context_menu: false, // 前・後文脈の入力フォームを表示するかどうかのフラグ
      media: null, // API から取得した全メディア情報
      period: null, // API から取得した全年代情報
      check_all: false, // "全てにチェックを入れる" ボタンのトリガ
      check_period: false, // すべての年代をチェックするためのトリガ
      uncheck_all: false, // "全てのチェックを外す" ボタンのトリガ
      checked_media: [], // チェックを入れたメディア
      checked_genre: [], // チェックを入れたジャンル
      checked_subgenre: [], // チェックを入れたサブジャンル
      checked_entire_period: [], // チェックを入れた全期間
      checked_decade: [], // チェックを入れた年代
      checked_year: [], // チェックを入れた年
      is_searching: false, // 検索中かどうかを表すフラグ
    };
  },
  created: function () {
    // ページを開いた際にメディア・年代・検索者数の情報を API から取得する
    this.getMediaConfig().then((response) => {
      this.media = response.data.Results;
    });
    this.getPeriodConfig().then((response) => {
      this.period = response.data.Results;
    });
    this.getCounter().then((response) => {
      this.counter = response.data.Counter;
    });
  },
  mounted: function () {
    // sessionStorage から前回の検索条件を読み出す。
    if (sessionStorage.query_string) {
      this.query_string = sessionStorage.query_string;
    }
    if (sessionStorage.lcontext_regex) {
      this.lcontext_regex = sessionStorage.lcontext_regex;
    }
    if (sessionStorage.rcontext_regex) {
      this.rcontext_regex = sessionStorage.rcontext_regex;
    }
    // 前回の検索条件の前後文脈に入力があった場合には、前後文脈のメニューを開く。
    if (sessionStorage.lcontext_regex || sessionStorage.rcontext_regex) {
      this.show_context_menu = true;
    }
  },
  watch: {
    // 検索条件を sessionStorage に保存する
    query_string(str) {
      sessionStorage.query_string = str;
    },
    lcontext_regex(str) {
      sessionStorage.lcontext_regex = str;
    },
    rcontext_regex(str) {
      sessionStorage.rcontext_regex = str;
    },
    checked_media(arr) {
      sessionStorage.checked_media = arr.toString();
    },
    checked_genre(arr) {
      sessionStorage.checked_genre = arr.toString();
    },
    checked_subgenre(arr) {
      sessionStorage.checked_subgenre = arr.toString();
    },
    checked_entire_period(arr) {
      sessionStorage.checked_entire_period = arr.toString();
    },
    checked_decade(arr) {
      sessionStorage.checked_decade = arr.toString();
    },
    checked_year(arr) {
      sessionStorage.checked_year = arr.toString();
    },
  },
  methods: {
    /**
     * メディア・年代のチェックを入れた際に実行する関数
     * @param {string} name メディア・年代などの種類を表す名前
     * @param {string} value チェックを入れたカテゴリ名
     */
    check(name, value) {
      var target = this["checked_" + name];
      if (!target) {
        target = [];
      }
      if (!target.includes(value)) {
        target.push(value);
      }
    },
    /**
     * メディア・年代のチェックを外した際に実行する関数
     * @param {string} name メディア・年代などの種類を表す名前
     * @param {string} value チェックを外すカテゴリ名
     */
    uncheck(name, value) {
      var target = this["checked_" + name];
      if (target) {
        target.splice(target.indexOf(value), 1);
      }
    },
    /**
     * メディア情報を API から取得する関数
     */
    getMediaConfig() {
      return this.$api.get("/config/media", {
        params: {},
      });
    },
    /**
     * 年代情報を API から取得する関数
     */
    getPeriodConfig() {
      return this.$api.get("/config/period", {
        params: {},
      });
    },
    /**
     * 検索者数を API から取得する関数
     */
    getCounter() {
      return this.$api.get("/counter", {
        params: {},
      });
    },
    /**
     * 検索ボタンを押下した時に実行する関数
     */
    submit() {
      // 検索文字列長の評価
      if (!this.query_string || this.query_string <= 0) {
        alert("検索文字列を入力してください。");
        return;
      } else if (this.query_string.length > this.query_string_max_length) {
        alert(
          "検索文字列を " +
            this.query_string_max_length +
            " 文字以内にしてください。"
        );
        return;
      }

      if (
        (!this.checked_media || this.checked_media.length <= 0) &&
        (!this.checked_genre || this.checked_genre.length <= 0) &&
        (!this.checked_subgenre || this.checked_subgenre.length <= 0)
      ) {
        // メディア・ジャンルが選択されていない場合
        this.to_result_page(
          false,
          "検索対象とするメディア／ジャンルを少なくとも一つは選択してください。"
        );
        return;
      }

      if (
        (!this.checked_entire_period ||
          this.checked_entire_period.length <= 0) &&
        (!this.checked_decade || this.checked_decade.length <= 0) &&
        (!this.checked_year || this.checked_year.length <= 0)
      ) {
        // 期間が選択されていない場合
        this.to_result_page(
          false,
          "検索対象とする期間を少なくとも一つは選択してください。"
        );
        return;
      }

      this.is_searching = true;
      // 検索リクエストを POST
      this.get_results()
        .then((response) => {
          this.is_searching = false;
          if (response.data.error) {
            switch (response.data.error.type) {
              case "search_timeout": // 検索タイムアウトのレスポンスを受け取った時
                this.to_result_page(false, "検索がタイムアウトしました。");
                return;
              default:
                // 定義されていないエラーが返ってきたとき (本来ここには入らない)
                this.to_result_page(
                  false,
                  "システム内部でエラーが発生しました。ご迷惑をおかけして申し訳ありません。"
                );
                break;
            }
          }
          // 正しく結果が返ってきた場合
          Vue.prototype.$results = response.data;
          this.to_result_page(true, "");
        })
        .catch((error) => {
          this.is_searching = false;
          if (error.response && error.response.status) {
            // response が返ってきている場合はステータスコードを見る
            switch (error.response.status) {
              case 400: // 正規表現の誤りがあった場合や存在しないメディア情報を指定した場合
              case 422: // POST したデータの型に誤りがあった場合 (FastAPI の仕組みで自動的に返される)
                this.to_result_page(
                  false,
                  "検索条件を正しく指定してください。"
                );
                break;
              case 504: // nginx に設定されたタイムアウト時間を超えた場合
                this.to_result_page(false, "検索がタイムアウトしました。");
                break;
              default:
                // 想定外のステータスコードが返ってきた場合
                this.to_result_page(
                  false,
                  "システム内部でエラーが発生しました。ご迷惑をおかけして申し訳ありません。"
                );
                break;
            }
          } else if (error.code === "ECONNABORTED") {
            // .env の VUE_APP_API_TIMEOUT_MS で設定した時間を超えた場合
            this.to_result_page(false, "検索がタイムアウトしました。");
          } else {
            // 想定外のエラーが発生した場合
            this.to_result_page(
              false,
              "システム内部でエラーが発生しました。ご迷惑をおかけして申し訳ありません。"
            );
          }
        });
      return;
    },
    // API に対して POST を実行し、レスポンスを受け取る関数
    get_results() {
      // POST するデータの作成
      const params = new URLSearchParams();
      params.append("query_string", this.query_string);
      params.append("lcontext_regex", this.lcontext_regex);
      params.append("rcontext_regex", this.rcontext_regex);
      if (this.checked_media) {
        if (Array.isArray(this.checked_media)) {
          this.checked_media.forEach((element) => {
            params.append("media", element);
          });
        } else {
          params.append("media", this.checked_media);
        }
      }
      if (this.checked_genre) {
        if (Array.isArray(this.checked_genre)) {
          this.checked_genre.forEach((element) => {
            params.append("genre", element);
          });
        } else {
          params.append("genre", this.checked_genre);
        }
      }
      if (this.checked_subgenre) {
        if (Array.isArray(this.checked_subgenre)) {
          this.checked_subgenre.forEach((element) => {
            params.append("subgenre", element);
          });
        } else {
          params.append("subgenre", this.checked_subgenre);
        }
      }
      if (this.checked_entire_period) {
        params.append("entire_period", this.checked_entire_period);
      }
      if (this.checked_decade) {
        if (Array.isArray(this.checked_decade)) {
          this.checked_decade.forEach((element) => {
            params.append("decade", element);
          });
        } else {
          params.append("decade", this.checked_decade);
        }
      }
      if (this.checked_year) {
        if (Array.isArray(this.checked_year)) {
          this.checked_year.forEach((element) => {
            params.append("year", element);
          });
        } else {
          params.append("year", this.checked_year);
        }
      }
      // POST を実行
      return this.$api.post("/results", params, { withCredentials: true });
    },
    /**
     * 結果ページに移行する
     * @param {boolean} is_success 検索結果が取得できていれば true にする
     * @param {string} msg エラー時の表示メッセージを指定する
     */
    to_result_page(is_success, msg) {
      this.$router.push({
        name: "result",
        params: {
          success: is_success,
          message: msg,
        },
      });
    },
    /**
     * クリアボタンを押下した際に実行する関数
     */
    reset() {
      this.query_string = "";
      this.lcontext_regex = "";
      this.rcontext_regex = "";
      this.check_all = !this.check_all;
      this.check_period = !this.check_period;
    },
  },
};
</script>

<style scoped src="../../public/css/search.css"></style>
