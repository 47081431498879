<!--
Tooltip.vue: Tooltip を表示する vue コンポーネント
-->

<template src="@/assets/html/tooltip.html"></template>

<script>
export default {
  props: [
    "visible", // tooltip を表示するかどうかを表すフラグ
    "tooltip_ctl", // tooltip の座標情報
    "tooltip_msg", // tooltip に表示するメッセージ
  ],
  beforeCreate: function () {
    document.body.className = "body-search";
  },
};
</script>

<style scoped src="../../public/css/search.css"></style>
<style scoped src="../../public/css/tooltip.css"></style>
