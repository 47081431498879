import { render, staticRenderFns } from "@/assets/html/dialog_terms_of_use.html?vue&type=template&id=08d9ab27&scoped=true&"
import script from "./Dialog.vue?vue&type=script&lang=js&"
export * from "./Dialog.vue?vue&type=script&lang=js&"
import style0 from "../../public/css/dialog.css?vue&type=style&index=0&lang=css&"
import style1 from "../../public/css/base.css?vue&type=style&index=1&id=08d9ab27&scoped=true&lang=css&"
import style2 from "../../public/css/retro/jquery-ui-1.9.0.custom.css?vue&type=style&index=2&id=08d9ab27&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d9ab27",
  null
  
)

export default component.exports