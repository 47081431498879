<!--
ResultPage.vue: 検索結果ページを表示する vue コンポーネント
-->

<template src="@/assets/html/search_result.html"></template>

<script>
import Vue from "vue";
import Tooltip from "./Tooltip.vue";

export default {
  title: "KOTONOHA「現代日本語書き言葉均衡コーパス」　少納言 - 検索結果",
  name: "SearchResult",
  components: {
    Tooltip,
  },
  data() {
    return {
      results: [], // 検索結果
      total_count: 0, // 全検索結果数
      sort_type: "index", // 現在のソート対象のカラム
      sort_order: 1, // ソート順序 1:昇順, -1:降順
      visible_tooltip: false, // "編集者等" カラムの注釈の表示フラグ
      tooltip_ctl: {
        // "編集者等" カラムの表示位置
        top: "-9999px",
        left: "-9999px",
      },
    };
  },
  mounted: function () {
    this.results = Vue.prototype.$results.Results;
    this.total_count = Vue.prototype.$results.TotalCount;
  },
  computed: {
    /**
     * 受け取った検索結果を指定されたカラムでソートして返す
     */
    sorted_results: function () {
      return this.results.slice().sort((a, b) => {
        if (a[this.sort_type] == null || b[this.sort_type] == null) {
          return 0;
        }
        var a_var = a[this.sort_type];
        var b_var = b[this.sort_type];
        if (this.sort_type === "lcontext") {
          a_var = a[this.sort_type].split("").reverse().join("");
          b_var = b[this.sort_type].split("").reverse().join("");
        }
        if (a_var > b_var) {
          return this.sort_order;
        } else if (a_var < b_var) {
          return -1 * this.sort_order;
        }
        return 0;
      });
    },
    /**
     * ランダムに選択された検索結果の結果数 (Max 500 件) を返す
     */
    results_count: function () {
      return this.results.length;
    },
    /**
     * ソートに利用されているカラムの種類と昇順/降順が指定された状態かどうか判定する
     */
    sortasc_index: function () {
      return this.sort_type === "index" && this.sort_order == 1;
    },
    sortdesc_index: function () {
      return this.sort_type === "index" && this.sort_order == -1;
    },
    sortasc_lcontext: function () {
      return this.sort_type === "lcontext" && this.sort_order == 1;
    },
    sortdesc_lcontext: function () {
      return this.sort_type === "lcontext" && this.sort_order == -1;
    },
    sortasc_rcontext: function () {
      return this.sort_type === "rcontext" && this.sort_order == 1;
    },
    sortdesc_rcontext: function () {
      return this.sort_type === "rcontext" && this.sort_order == -1;
    },
    sortasc_author: function () {
      return this.sort_type === "author" && this.sort_order == 1;
    },
    sortdesc_author: function () {
      return this.sort_type === "author" && this.sort_order == -1;
    },
    sortasc_birth_year: function () {
      return this.sort_type === "birth_year" && this.sort_order == 1;
    },
    sortdesc_birth_year: function () {
      return this.sort_type === "birth_year" && this.sort_order == -1;
    },
    sortasc_gender: function () {
      return this.sort_type === "gender" && this.sort_order == 1;
    },
    sortdesc_gender: function () {
      return this.sort_type === "gender" && this.sort_order == -1;
    },
    sortasc_media_full: function () {
      return this.sort_type === "media_full" && this.sort_order == 1;
    },
    sortdesc_media_full: function () {
      return this.sort_type === "media_full" && this.sort_order == -1;
    },
    sortasc_title: function () {
      return this.sort_type === "title" && this.sort_order == 1;
    },
    sortdesc_title: function () {
      return this.sort_type === "title" && this.sort_order == -1;
    },
    sortasc_subtitle: function () {
      return this.sort_type === "subtitle" && this.sort_order == 1;
    },
    sortdesc_subtitle: function () {
      return this.sort_type === "subtitle" && this.sort_order == -1;
    },
    sortasc_number: function () {
      return this.sort_type === "number" && this.sort_order == 1;
    },
    sortdesc_number: function () {
      return this.sort_type === "number" && this.sort_order == -1;
    },
    sortasc_bibauthor: function () {
      return this.sort_type === "bibauthor" && this.sort_order == 1;
    },
    sortdesc_bibauthor: function () {
      return this.sort_type === "bibauthor" && this.sort_order == -1;
    },
    sortasc_publisher: function () {
      return this.sort_type === "publisher" && this.sort_order == 1;
    },
    sortdesc_publisher: function () {
      return this.sort_type === "publisher" && this.sort_order == -1;
    },
    sortasc_publication_year: function () {
      return this.sort_type === "publication_year" && this.sort_order == 1;
    },
    sortdesc_publication_year: function () {
      return this.sort_type === "publication_year" && this.sort_order == -1;
    },
  },
  methods: {
    /**
     * 検索結果のカラムを押下した際に実行する関数
     * @param {string} name カラム名
     */
    select_column(name) {
      if (this.sort_type === name) {
        this.sort_order = this.sort_order * -1;
      } else {
        this.sort_type = name;
        this.sort_order = 1;
      }
    },
    /**
     * "編集者等" カラムにマウスオーバーした際に注釈を表示するための関数
     */
    move_tooltip(e) {
      this.tooltip_ctl.top = e.clientY - 115 + "px";
      this.tooltip_ctl.left = e.clientX - 60 + "px";
    },
  },
};
</script>

<style scoped src="../../public/css/search.css"></style>
