<!--
ErrorPage.vue: 検索エラーページを表示する vue コンポーネント

ページの遷移前にエラーメッセージのパラメータを受け取って表示する。
-->

<template src="@/assets/html/error.html"></template>

<script>
export default {
  title: "KOTONOHA「現代日本語書き言葉均衡コーパス」　少納言 - エラーページ",
  name: "SearchError",
  data() {
    return {};
  },
  props: ["message"],
};
</script>

<style scoped src="../../public/css/search.css"></style>
